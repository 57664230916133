import React, { useEffect } from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { HomePage } from './pages/HomePage';
import { CountriesPage } from './pages/CountriesPage';
import { PricesPage } from './pages/PricesPage';
import { PrivacyPolicyPage } from './pages/PrivacyPolicyPage';
import { TermsAndConditionsPage } from './pages/TermsAndConditionsPage';
import { ContactsPage } from './pages/ContactsPage';

import { LoginPage } from './pages/LoginPage';
import { RegisterPage } from './pages/RegisterPage';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { ResidentialProxyPool1Page } from './pages/account/shop/ResidentialProxyPool1Page';
import { ResidentialProxyPool2Page } from './pages/account/shop/ResidentialProxyPool2Page';
import { WalletPage } from './pages/account/WalletPage';
import { AccountHomePage } from './pages/account/AccountHomePage';
import { ChangeDataPage } from './pages/account/ChangeDataPage';
import { ResellPage } from './pages/account/ResellPage';
import { NewsletterDisablePage } from './pages/account/NewsletterDisablePage';
import { NotFoundPage } from './pages/NotFoundPage';
import { OrderResidentialProxyPool1Page } from './pages/account/orders/OrderResidentialProxyPool1Page';
import { OrderResidentialProxyPool2Page } from './pages/account/orders/OrderResidentialProxyPool2Page';
import { SupportCataloguePage } from './pages/support/SupportCataloguePage';
import { SupportIpSkippingPage } from './pages/support/SupportIpSkippingPage';
import { SupportWhitelistingIpsPage } from './pages/support/SupportWhitelistingIpsPage';
import { SupportPool1Terms } from './pages/support/SupportPool1Terms';

import ProtectedRoute from './components/ProtectedRoute';
import ProfileLayout from './components/ProfileLayout';
import LandingLayout from './components/LandingLayout';

import './styles/reset.css';
import './styles/fonts.css';
import './styles/style.css';

function App() {
  useEffect(() => {
    const handleInputChange = (event) => {
      const inputElement = event.target;
      const min = parseFloat(inputElement.getAttribute('min'));
      const max = parseFloat(inputElement.getAttribute('max'));

      if (!isNaN(min) && !isNaN(max)) {
        const value = parseFloat(inputElement.value);

        if (value < min) {
          inputElement.value = min;
        } else if (value > max) {
          inputElement.value = max;
        }
      }
    };

    document.addEventListener('input', handleInputChange);

    return () => {
      document.removeEventListener('input', handleInputChange);
    };
  }, []);

  return (
    <Routes>
      <Route path='/' element={<LandingLayout />}>
        <Route index element={<HomePage />} />
        <Route path='/prices' element={<PricesPage />} />
        <Route path='/countries' element={<CountriesPage />} />
        <Route path='/terms-and-conditions' element={<TermsAndConditionsPage />} />
        <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
        <Route path='/contacts' element={<ContactsPage />} />
      </Route>
      <Route path='/support' element={<LandingLayout />}>
        <Route index element={<SupportCataloguePage />} />
        <Route path='/support/ip-skipping' element={<SupportIpSkippingPage />} />
        <Route path='/support/whitelisting-ips' element={<SupportWhitelistingIpsPage />} />
        <Route path='/support/pool-1-terms' element={<SupportPool1Terms />} />
      </Route>
      <Route path='/login' element={<LoginPage />} />
      <Route path='/register' element={<RegisterPage />} />
      <Route path='/forgot-password' element={<ForgotPasswordPage />} />
      <Route path='/reset-password' element={<ResetPasswordPage />} />
      <Route element={<ProtectedRoute><ProfileLayout /></ProtectedRoute>}>
        <Route path='/account' element={<AccountHomePage />} />
        <Route path='/account/newsletter-disable' element={<NewsletterDisablePage />} />
        <Route path='/account/change-data' element={<ChangeDataPage />} />
        <Route path='/account/shop' element={<AccountHomePage />} />
        <Route path='/account/shop/residential-proxy' element={<AccountHomePage />} />
        <Route path='/account/shop/residential-proxy/pool-1' element={<ResidentialProxyPool1Page />} />
        <Route path='/account/shop/residential-proxy/pool-2' element={<ResidentialProxyPool2Page />} />
        <Route path='/account/wallet' element={<WalletPage />} />
        <Route path='/account/orders/residential-proxy' element={<AccountHomePage />} />
        <Route path='/account/orders/residential-proxy/pool-1' element={<OrderResidentialProxyPool1Page />} />
        <Route path='/account/orders/residential-proxy/pool-2' element={<OrderResidentialProxyPool2Page />} />
        {/* <Route path='/account/partnership' element={<ResellPage />} /> */}
      </Route>
      <Route path='/*' element={<LandingLayout />}>
        <Route path='*' element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}

export default App;
