function formatDate(isoDateString, format = 'yyyy-MM-dd h:m') {
    const date = new Date(isoDateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const shortMonth = shortMonthNames[date.getMonth()];

    const formats = {
        'yyyy-MM-dd h:m:s': `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`,
        'yyyy-MM-dd h:m': `${year}-${month}-${day} ${hours}:${minutes}`,
        'yyyy-MM-dd': `${year}-${month}-${day}`,
        'yyyy-MM': `${year}-${month}`,
        'yyyy-month-dd': `${day} ${shortMonth} ${year}`,
    };

    return formats[format] || formats['yyyy-MM-dd h:m:s'];
}

export default formatDate;
