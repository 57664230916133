import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import {
    format,
    startOfMonth,
    endOfMonth,
    add,
    sub,
    eachDayOfInterval,
    subDays,
    startOfDay,
    sub as subtract,
} from "date-fns";

import style from "./DatePicker.scss";

const DatePicker = ({
    mode = "single",
    initialMonth = new Date(),
    initialSelectedDate = { start_date: null, end_date: null },
    onDateChange = () => {},
    onPeriodSelect = () => {},
    onClear = () => {},
    allowPastDates = true,
    allowFutureDates = true,
}) => {
    const { t } = useTranslation();

    const [currentMonth, setCurrentMonth] = useState(initialMonth);
    const [selectedDate, setSelectedDate] = useState(initialSelectedDate);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const pickerRef = useRef(null);
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);

    const daysInMonth = eachDayOfInterval({
        start: startOfMonth(currentMonth),
        end: endOfMonth(currentMonth),
    });

    const handleDateClick = (date) => {
        const today = startOfDay(new Date());
        if (!allowPastDates && date < today) return;
        if (!allowFutureDates && date > today) return;

        if (mode === "single") {
            const newSelectedDate = { start_date: date, end_date: date };
            setSelectedDate(newSelectedDate);
            onDateChange(newSelectedDate);
        } else if (mode === "range") {
            if (!selectedDate.start_date || selectedDate.end_date) {
                const newSelectedDate = { start_date: date, end_date: null };
                setSelectedDate(newSelectedDate);
            } else {
                const newSelectedDate =
                    date < selectedDate.start_date
                        ? { start_date: date, end_date: selectedDate.start_date }
                        : { start_date: selectedDate.start_date, end_date: date };
                setSelectedDate(newSelectedDate);
                onDateChange(newSelectedDate);
            }
            setSelectedPeriod("custom");
            onPeriodSelect("custom");
        }
    };

    const isDateSelected = (date) => {
        if (!selectedDate.start_date) return false;
        if (!selectedDate.end_date) {
            return format(date, "yyyy-MM-dd") === format(selectedDate.start_date, "yyyy-MM-dd");
        }
        return date >= selectedDate.start_date && date <= selectedDate.end_date;
    };

    const handlePeriodChange = (period) => {
        setSelectedPeriod(period);
        const today = startOfDay(new Date());
        if (period === "last-24-hours") {
            const start = subtract(today, { hours: 24 });
            if (!allowPastDates && start < today) return;
            const newSelectedDate = { start_date: start, end_date: today };
            setSelectedDate(newSelectedDate);
            onDateChange(newSelectedDate);
        } else if (period === "last-30-days") {
            const start = subtract(today, { days: 30 });
            if (!allowPastDates && start < today) return;
            const newSelectedDate = { start_date: start, end_date: today };
            setSelectedDate(newSelectedDate);
            onDateChange(newSelectedDate);
        }
        onPeriodSelect(period);
    };

    const clearSelection = () => {
        setSelectedDate({ start_date: null, end_date: null });
        setSelectedPeriod(null);
        onClear();
    };

    const handleClickOutside = (event) => {
        if (pickerRef.current && !pickerRef.current.contains(event.target)) {
            setIsCalendarOpen(false);
        }
    };

    const renderDays = () => {
        const today = startOfDay(new Date());
        return daysInMonth.map((day) => {
            const isSelected = isDateSelected(day);
            const isDisabled =
                (!allowPastDates && day < today) ||
                (!allowFutureDates && day > today);
            return (
                <div
                    key={day}
                    className={`date-picker__day ${isSelected ? "selected" : ""} ${isDisabled ? "disabled" : ""}`}
                    onClick={() => !isDisabled && handleDateClick(day)}
                >
                    {format(day, "d")}
                </div>
            );
        });
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="date-picker" ref={pickerRef}>
            <div
                className="date-picker__title"
                onClick={() => setIsCalendarOpen(!isCalendarOpen)}
            >
                {selectedDate.start_date
                    ? `${format(selectedDate.start_date, "yyyy-MM-dd")} - ${format(selectedDate.end_date, "yyyy-MM-dd")}`
                    : t("name-select-date")}
            </div>
            {isCalendarOpen && (
                <div className="sub-menu">
                    <div className="date-picker__period">
                        <h4 className="date-picker__h4">{t("name-period")}</h4>
                        <div className="checkbox-wrapper">
                            <label>
                                <input
                                    type="radio"
                                    name="selected-period"
                                    value="last-24-hours"
                                    onChange={(e) => handlePeriodChange(e.target.value)}
                                    checked={selectedPeriod === "last-24-hours"}
                                />
                                {t("name-last-24-hours")}
                            </label>
                        </div>
                        <div className="checkbox-wrapper">
                            <label>
                                <input
                                    type="radio"
                                    name="selected-period"
                                    value="last-30-days"
                                    onChange={(e) => handlePeriodChange(e.target.value)}
                                    checked={selectedPeriod === "last-30-days"}
                                />
                                {t("name-last-30-days")}
                            </label>
                        </div>
                        <div className="checkbox-wrapper">
                            <label>
                                <input
                                    type="radio"
                                    name="selected-period"
                                    value="custom"
                                    onChange={(e) => setSelectedPeriod(e.target.value)}
                                    checked={selectedPeriod === "custom"}
                                />
                                {t("name-individual-period")}
                            </label>
                        </div>
                    </div>
                    <div className="date-picker__header">
                        <button onClick={() => setCurrentMonth(sub(currentMonth, { months: 1 }))}>{`<`}</button>
                        <h4 className="date-picker__h4">{format(currentMonth, "MMMM yyyy")}</h4>
                        <button onClick={() => setCurrentMonth(add(currentMonth, { months: 1 }))}>{`>`}</button>
                    </div>
                    <div className="date-picker__content">
                        <div className="date-picker__days-grid">{renderDays()}</div>
                    </div>
                    <div className="date-picker__footer">
                        <button className="date-picker__clear" onClick={clearSelection}>{t("name-clear")}</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DatePicker;
